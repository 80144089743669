import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getUserCredentialByEmail } from "store/userSlice";
import MenuTopBar from "./components/MenuTopBar";
import SideBar from "./components/SideBar";
import { useLayoutServices } from "./services/layout.service";

Layout.propTypes = {};

function Layout() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.current);
  const { getListCommonComponent } = useLayoutServices(user);

  useEffect(() => {
    if (user?.id) {
      getListCommonComponent();
    }
  }, [user]);

  const fetchUser = async () => {
    const actions = getUserCredentialByEmail();
    await dispatch(actions);
  };

  // const handleChangeStateUser = async (status) => {
  //   await UserAPI.changeState(status);
  // };

  // // Khi close tab thi se change state user sang Unavailable
  // const handleTabClose = (event) => {
  //   event.preventDefault();
  //   handleChangeStateUser("Unavailable");
  // };
  // // Khi open tab thi se change state user sang Available
  // const handleActiveTab = (event) => {
  //   event.preventDefault();
  //   handleChangeStateUser("Available");
  // };

  useEffect(() => {
    fetchUser();
    // useEffect bên dưới : Nếu button Remember me không được tick thì dữ liệu Local Storage sẽ bị clear()

    // window.addEventListener("beforeunload", handleTabClose);
    // // window.addEventListener("focus", handleActiveTab);
    // return () => {
    //   window.removeEventListener("beforeunload", handleTabClose);
    //   // window.removeEventListener("focus", handleActiveTab);
    // };
  }, []);

  return (
    <div className="w-screen h-screen flex overflow-hidden flex-col">
      <MenuTopBar />
      <div className="flex h-[calc(100vh-54px)] relative">
        <div className=" bg-[#001b48] text-white pt-5 absolute top-0 left-0 h-full lg:w-[250px] w-[54px]">
          <SideBar />
        </div>

        <div className="flex-1 flex flex-col text-black">
          <div className="bg-bg_primary  h-full overflow-hidden ml-[54px] lg:ml-[250px]">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
      <ToastContainer icon={false} />
    </div>
  );
}

export default Layout;
