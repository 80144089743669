import { Checkbox } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { unwrapResult } from "@reduxjs/toolkit";
import TokenServices from "api/TokenServices";
import background from "assets/images/login/LoginBackground.png";
import logo from "assets/images/shared/TTDLogo2.jpg";
import { Formik } from "formik";
import ButtonComponent from "pages/authentication/components/ButtonComponent";
import InputComponent from "pages/authentication/components/InputComponent";
import InputPasswordComponent from "pages/authentication/components/InputPasswordComponent";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMenuAccess } from "store/commonSlice";
import { changeUserIdSelected } from "store/timesheetSlice";
import { login, loginByGoogleAccount } from "store/userSlice";
import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  userName: Yup.string().email("Email không không đúng định dạng").required("Email không được để trống"),
  password: Yup.string().required("Password không được để trống")
});

export default function LoginPage() {
  const navigate = useNavigate();
  const [isRememberMe, setIsRememberMe] = useState(false);
  const initialValues = { userName: "", password: "" };
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.current);
  const [errorLoginGoogle, setErrorLoginGoogle] = useState("");

  const handleLogin = async (values, { setErrors }) => {
    const { userName, password } = values;
    try {
      TokenServices.removeUser();
      TokenServices.setLocalStorageRemember(isRememberMe);
      const actions = login({
        username: userName,
        password: password,
        setErrors: setErrors
      });
      const resultAction = await dispatch(actions);
      const userUn = unwrapResult(resultAction);
      if (userUn?.id) {
        navigate("/dashboard", { replace: true });
        await dispatch(getMenuAccess());
        await dispatch(changeUserIdSelected(userUn?.id));
      }
      return {
        ...user,
        value: userUn
      };
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      TokenServices.removeUser();
      const actions = loginByGoogleAccount({
        credential: credentialResponse?.access_token,
        setErrorLoginGoogle: setErrorLoginGoogle
      });
      const resultAction = await dispatch(actions);
      const userUn = unwrapResult(resultAction);
      if (userUn?.id) {
        navigate("/dashboard", { replace: true });
      }
      return {
        ...user,
        value: userUn
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loginUseGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleGoogleLogin(tokenResponse);
    }
  });

  return (
    <div
      style={{
        background: `linear-gradient(0, rgb(0 0 0 / 81%), rgb(175 175 175 / 25%)), url(${background})`
      }}
      className="h-screen w-screen flex justify-center items-center"
    >
      <div className="p-5 rounded-lg bg-white flex flex-col">
        <div className="w-full flex items-center justify-center gap-2 mb-[10px] pr-3">
          <img src={logo} alt="ttd-logo" className="h-[54px] object-contain w-[54px] border-none" />
          <h1 className="text-[42px] font-medium">{"T&T Design"}</h1>
        </div>
        <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleLogin}>
          {({ values, handleChange, errors, handleSubmit, setErrors }) => (
            <div className="space-y-[10px] flex flex-col">
              <div className="flex flex-col gap-[2px] items-start justify-start">
                <label htmlFor="userName" className="text-sm font-medium  pl-2">
                  Email
                </label>
                <InputComponent
                  autoFocus
                  type="userName"
                  name="userName"
                  id="userName"
                  className="bg-[#DCDCDC]  rounded-lg block p-2.5 w-[310px] text-sm px-2 py-[10px] outline-none border-none"
                  placeholder="email@ttdesignco.com"
                  value={values.userName}
                  onChange={handleChange}
                ></InputComponent>
                <div className="h-[16px] w-full">
                  {errors.userName && (
                    <span className="text-sm text-red-400 line-clamp-1 pl-[10px]">{errors.userName}</span>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-[2px] items-start justify-start">
                <label htmlFor="password" className="text-sm font-medium  pl-2">
                  Password
                </label>
                <InputPasswordComponent
                  name="password"
                  id="password"
                  className="bg-[#DCDCDC]  rounded-lg block p-2.5 w-[310px] text-sm px-2 py-[10px] outline-none border-none"
                  value={values.password}
                  autoComplete
                  onChange={handleChange}
                ></InputPasswordComponent>
                <div className="h-[16px] w-full">
                  {errors.password && (
                    <span className="text-sm text-red-400 line-clamp-1 pl-[10px]">{errors.password}</span>
                  )}
                </div>
              </div>

              <div className="flex flex-row items-center justify-between">
                <div className="flex items-center justify-center">
                  <Checkbox
                    checked={isRememberMe}
                    onChange={(event) => setIsRememberMe(event.target.checked)}
                    id="rememberMe"
                    sx={{
                      padding: "0px"
                    }}
                  />
                  <label htmlFor="rememberMe" className="text-[#1E1E1E] text-sm italic select-none pl-1">
                    Remember me
                  </label>
                </div>
                <button onClick={() => navigate("/forgot-password")}>
                  <span className="text-[#0045B7] text-sm italic">Forgot password?</span>
                </button>
              </div>

              <ButtonComponent onClick={() => handleSubmit(values, setErrors)}>
                <span className="!font-medium text-sm ">Login</span>
              </ButtonComponent>

              <ButtonComponent onClick={() => loginUseGoogle()}>
                <span className="!font-medium text-sm ">Sign in with Google account</span>
              </ButtonComponent>
              {errorLoginGoogle && (
                <span className="text-sm text-red-400 line-clamp-1 pl-[10px]">{errorLoginGoogle}</span>
              )}

              <span className="text-center text-sm">Don’t have account? Please contact admin</span>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
